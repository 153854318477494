import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";

const ContentPage = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO refKey="server-backup"
      title="Server Backup Solution – but effortless"
      description="Back up your server and website without a single line of code. Say hello to a new standard of server data protection in your company."
    />
    <Layout.Hero
      type="primary"
      title={(<><span className="text-primary">Server Backup Solution</span> – but effortless</>)}
      subTitle={(
        <>
          Back up your server and website without a single line of code. Say hello to a new standard of server data protection in your company.
        </>)}
      checkMarks={['Quick connect via SSH', 'Support any IaaS providers', 'Support private VPS']}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your Server Backup" urlAtrr={{ sb_source: "website", sb_term: "server-hero" }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-file-backup-hero.png" alt="SimpleBackups server & projects backup"/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <div className="pb-16 md:pb-28">
        <Sections.JoinBrandsSection text="Join the 6000+ developers, startups and enterprises using SimpleBackups to automate their file server backups"/>
      </div>
    </Ui.Container>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
        <div className="grid md:grid-cols-3 gap-16 text-cente">
          <div>
            <i className="fad fa-coffee-pot text-primary text-4xl" />
            <h3 className="text-xl font-bold mt-4 mb-1">Backups simpler than<br/>making coffee</h3>
            <p className="text-base">It takes 12 clicks to set up your first server backup. Yes, we checked.</p>
          </div>

          <div>
            <i className="fad fa-shield text-primary text-4xl" />
            <h3 className="text-xl font-bold mt-4 mb-1">Security better than a<br/> superhero's shield.</h3>
            <p className="text-base">We comply with GDPR, use SSL, and MFA. We nailed all the acronyms.</p>
          </div>

          <div>
            <i className="fad fa-bullseye-pointer text-primary text-4xl" />
            <h3 className="text-xl font-bold mt-4 mb-1">Compatibility more seamless<br/> than a hot knife through butter</h3>
            <p className="text-base">We support any IaaS and private VPS. You’ll also get 50+ integrations – with 0 additional cost.</p>
          </div>

        </div>
        <div className="flex items-center- mt-16 justify-around">
          <div className="md:w-1/2 rounded-xl bg-white p-8 flex flex-col justify-between text-left">
            <p className="text-lg">
              Simplebackups has been a great tool for our web development company. We currently have over 200 backups setup across 60 different webservers - <span className="link-highlight">all running automated backups</span> 👌!
            </p>

            <div className="flex items-center mt-6 gap-6">
              <div className="flex">
                <Ui.Image filename="testimonials-corby.jpeg" className="rounded-full w-14 h-14 block"/>
              </div>
              <div className="">
                <p className="font-bold text-lg tracking-snug">Corby Wenzelbach</p>
                <p className="text-primary text-base">
                  LS Graphics Inc.
                </p>
              </div>
            </div>
          </div>
        </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: "Optimize your server backups",
                title: (<>Data Loss?<br/><span className="text-primary">Not on Our Watch!</span></>),
                description: (
                  <>
                    <p>
                      Run your backups using optimized backups scripts built by experts and with the right flags for you.
                      Stop messing around with complex documentation.
                      <Ui.FeatureChecks items={['Backup Streaming', 'Serverless backup', 'Controlled environment']} />
                      <Ui.Link arrow="right" to="#features">View all features</Ui.Link>
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-app-file-backup.png'
                  alt="Server, files and folder backup"
                  style={{maxWidth: 550}}
                />)
              },
              {
                preTitle: "Optimize your server backups",
                title: (<>Secure Server Backups.<br/><span className="text-primary">Without Compromise</span></>),
                description: (
                  <>
                    <p>
                      SimpleBackups makes server backups effotless for you — but we put endless effort to make them secure. Benefit from the strict European requirements on all your backups, no matter where you’re based.
                      <Ui.FeatureChecks items={['GDPR Compliancy', 'Team & User Authorization', 'Multiple-Factor Auth', 'ISO-27001 certified', 'Backup Encryption', 'SSL & self-hosted keys']} />
                      <Ui.Link arrow="right" to="/security-first">How we treat your data</Ui.Link>
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  filename='simplebackups-default-backup-security-settings.png'
                  alt="Server, files and folder backup"
                  style={{maxWidth: 550}}
                />)
              },
              {
                preTitle: "Control your backups",
                title: (<>Your Server backup, stored where you want</>),
                description: (
                  <>
                    <p>
                      Store your Server backups on any Storage Provider using our built in integrations including SFTP drive, S3, or even your own server.

                      <Ui.FeatureChecks items={['Local Storage', 'Bring your own storage', 'Included SimpleStorage quota']} />
                      <Ui.Link arrow="right" to="/catalog/">Discover all integrations</Ui.Link>
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  filename={`simplebackups-default-backup-automation-providers.png`}
                  alt="Storage backup scheduling"
                  style={{maxWidth: 450}}
                />)
              },
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt" id="features">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">Want to geek out on the technical details?</Ui.Typography>
          <div className="text-center">
            <p className="mb-6">SimpleBackups offers a user-friendly setup process, perfect for those who may not be tech experts. For the tech-savvy, we're eager to dive into the details, tailoring SimpleBackups to meet your unique requirements and maximize its potential for you.</p>
            <p className="py-5 text-lg font-bold">
              All your applications, servers and websites backed up and monitored!<br/>
              Our users use SimpleBackups to backup their:
            </p>
            <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium">
              <li><i className="far fa-cloud text-green mr-2"></i>Drupal</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Wordpress</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Ruby on Rails apps</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Laravel Forge servers</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Cloudron.io servers</li>
              <li><i className="far fa-cloud text-green mr-2"></i>RunCloud servers</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Vultr servers</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Magento</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Laravel apps</li>
              <li><i className="far fa-cloud text-green mr-2"></i>ServerPilot servers</li>
              <li><i className="far fa-cloud text-green mr-2"></i>DigitalOcean servers</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Lightsail servers</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Moss.sh servers</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Linux servers, routers and smart hubs</li>
            </ul>

            <p className="mt-12 py-5 text-lg font-bold">Full-featured Server Backup service,... you’re covered:</p>
            <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium max-w-7xl">
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Point in time Backup restore</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Webhooks</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Trigger endpoints</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">SSL Support</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Self-hosted connections credentials</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Backup streaming</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Performance statistics</span>
              </li>
            </ul>
            <div className="my-16 text-center"><Ui.Link arrow="right" to="/features">View all features</Ui.Link></div>

            <Ui.Typography tag="h3" className="text-2xl text-center mt-16 mb-8">Wait... there's even more...</Ui.Typography>
            <Sections.FeatureSlider tags={[]} />
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about Server Backups?</>}
      text="Find out more and get started with our Server Backup guides"
      tags={['server']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={[
      'what_server_backup_service',
      'how_often_server_backup',
      'what_types_server_backup',
      'how_long_keep_server_backup']}/>

  </Layout.Layout>
)

export default ContentPage
